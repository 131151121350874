import React, { useState } from "react";
import drykkjarsedillISL from "../images/verbudinDrykkjarsedill-raudur.gif";
import drykkjarsedillEN from "../images/verbudinDrykkjarsedill-graenn.gif";

export const Drykkjarsedill = () => {
  const [language, setLanguage] = useState("IS");

  return (
    <div>
      <div className="absolute justify-start">
        <button
          onClick={() => {
            setLanguage("IS");
          }}
          className="w-20 m-3"
        >
          <img src="https://flagcdn.com/is.svg" alt="Íslenskt" />
        </button>
        <button
          onClick={() => {
            setLanguage("EN");
          }}
          className="w-20 m-3"
        >
          <img src="https://flagcdn.com/us.svg" alt="English" />
        </button>
      </div>
      <div className="flex">
        <img
          src={language === "IS" ? drykkjarsedillISL : drykkjarsedillEN}
          alt="Drykkjarsedill"
          objectFit="cover"
        />
      </div>
    </div>
  );
};
