import React from "react";
import { Drykkjarsedill } from "../components/Drykkjarsedill";

export const DrykkjarsedillPage = () => {
  return (
    <>
      <Drykkjarsedill />
    </>
  );
};
