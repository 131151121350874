import "./App.css";
import Footer from "./components/Footer";
import { Home } from "./pages/Home";
import { DrykkjarsedillPage } from "./pages/DrykkjarsedillPage";
import { PageNotFound } from "./pages/PageNotFound";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/drykkjarsedill",
    element: <DrykkjarsedillPage />,
  },
  {
    path: "*",
    element: <PageNotFound />,
  },
]);

function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
      <Footer />
    </div>
  );
}

export default App;
